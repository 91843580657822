define('@fortawesome/free-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faCircleDot = {
    prefix: 'far',
    iconName: 'circle-dot',
    icon: [512, 512, [128280, "dot-circle"], "f192", "M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"]
  };

  exports.faCircleDot = faCircleDot;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
